import cn from 'classnames'
import { decode } from 'html-entities'
import { NextPage } from 'next'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'

function decodeHtmlEntities(text: string): string {
  return decode(text)
}

export interface SmartSearchSources {
  description?: string | null
  logo?: string | null
  sourceUrl?: string | null
  title?: string | null
}
interface LoadingAnimationHrProps {
  className?: string
  result: string
  sources: SmartSearchSources[]
}

interface CardComponentProps {
  title?: string
  description?: string
  imgUrl?: string
  linkTitle?: string
  linkUrl?: string
  shimRed: boolean
}
const CardComponent: React.FC<CardComponentProps> = ({
  title = '',
  description = '',
  imgUrl = '',
  linkTitle = '',
  linkUrl = '#',
  shimRed = true,
}) => {
  const imgSrc = imgUrl ? imgUrl : ''

  //myString.substring(4, 10)
  const [showProgress, setShowProgress] = useState(shimRed)
  const [descriptionText, setDescriptionText] = useState('')
  const [titleText, setTitleText] = useState('')
  let classProgess = showProgress ? 'shim-red' : ''
  let bg = showProgress ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'

  useEffect(() => {
    const descText = description.replace(/(<([^>]+)>)/gi, '')
    const subString = descText.substring(0, 95)

    const titleDesc = linkTitle.replace(/(<([^>]+)>)/gi, '')
    const titleD = titleDesc.substring(0, 29)
    if (descText.length > subString.length) {
      setDescriptionText(subString + '...')
    } else {
      setDescriptionText(subString)
    }
    if (titleDesc.length > titleD.length) {
      setTitleText(titleD + '...')
    } else {
      setTitleText(titleD)
    }

    setShowProgress(shimRed)

    classProgess = showProgress ? 'shim-red' : ''
    bg = showProgress ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'
  }, [shimRed])

  return (
    <div key={title} className={bg + '  items-center rounded-lg w-64 overflow-hidden'}>
      <Link
        href={linkUrl}
        passHref
        className="inline-flex items-center justify-center text-base"
        rel="noopener noreferrer"
        title={linkTitle}
        target="_blank"
      >
        <div className="p-2 grid justify-center">
          {/* <h3 className="relative w-[220px] h-[28px] bg-white rounded-[1rem] pl-2 font-bold mb-2 text-sm leading-6">
            <div className={'w-full absolute top-0 h-[28px] rounded-[1rem] truncate ' + classProgess}>{title}</div>
          </h3> */}
          <p className="w-[232px] h-[28px] flex flex-row text-xs leading-6">
            {imgSrc && <img src={imgSrc} alt="Card Image" className="w-[24px] h-[24px] mr-1 object-fit" />}
            {!imgSrc && (
              <span className="relative w-1/4 h-[24px] bg-white rounded-[1rem] mr-1">
                <span className={'w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess}></span>
              </span>
            )}
            <span
              className={
                'relative w-full h-[24px]  truncate pl-2  text-blue-500 rounded-lg bg-gray-50 hover:text-glue-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white hover:underline '
              }
            >
              <span
                className={
                  'text-ellipsis overflow-hidden ... w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess
                }
              >
                {titleText}
              </span>
            </span>
          </p>
          <p className="relative w-[232px] h-[80px] bg-white mb-2 text-xs leading-6">
            <span className={' w-[232px] absolute top-0 h-[80px]  ' + classProgess}>{descriptionText}</span>
          </p>
          {/* <p className="w-[226px] h-[28px] flex flex-row text-xs leading-6">
            {imgSrc && <img src={imgSrc} alt="Card Image" className="w-[24px] h-[24px] mr-1 object-fit" />}
            {!imgSrc && (
              <span className="relative w-1/4 h-[24px] bg-white rounded-[1rem] mr-1">
                <span className={'w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess}></span>
              </span>
            )}
            <Link
              href={linkUrl} passHref
              className="inline-flex items-center justify-center text-base text-blue-500 rounded-lg bg-gray-50 hover:text-glue-900 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white hover:underline relative w-full h-[24px] bg-white rounded-[1rem] truncate pl-2"
              rel="noopener noreferrer"
            >
              <span className={'w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess}>{linkTitle}</span>
            </Link>
          </p> */}
          {/* <div className="relative w-full sm:w-1/2 bg-gray-200 rounded">
            <div className="w-full absolute top-0 h-4 rounded shim-red"></div>
          </div> */}
        </div>
      </Link>
    </div>
  )
}

export const LoadingAnimationHr: NextPage<LoadingAnimationHrProps> = ({ className, result, sources }) => {
  const [resultHeight, setResultHeight] = useState('h-[100px]') //result ? 'hfull' : 'h-[100px]'
  const [classProgess, setClassProgess] = useState('shim-red')
  let bg = !result ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'

  useEffect(() => {
    setClassProgess('shim-red')
    setResultHeight('h-[100px]')
    bg = !result ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'
    if (result) {
      setClassProgess('')
      setResultHeight('h-full')
    }
  }, [result])
  const formattedText = result.replace(/\n/g, '<br>')

  return (
    <div className={cn('flex flex-col items-center')}>
      <div className="flex flex-row space-x-4 mb-4">
        <div className={bg + ' p-4 flex flex-row items-center rounded-lg overflow-hidden'}>
          <p
            className={'w-[784px] bg-white rounded-[1rem] p-2 mb-2 ' + resultHeight + ' ' + classProgess}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </div>
      </div>

      <div className={cn(className, 'justify-between mb-4')}>
        {sources.length > 0 ? (
          <>
            {sources.map((item: any) => (
              <CardComponent
                title={item.title}
                linkUrl={item.sourceUrl}
                linkTitle={item.title}
                description={decodeHtmlEntities(item.description || '')}
                imgUrl={item.logo}
                shimRed={item ? false : true}
              />
            ))}
          </>
        ) : result && sources.length == 0 ? (
          ''
        ) : (
          <>
            <CardComponent shimRed={true} />
            <CardComponent shimRed={true} />
            <CardComponent shimRed={true} />
          </>
        )}
      </div>
    </div>
  )
}
